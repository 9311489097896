.share-container {
    max-width: 1024px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  
  .slide-container {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  
  .each-fade {
    display: inline-block;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }