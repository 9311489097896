.image-container {
    position: relative;
  }
  
  .image-base, .image-headline, .image-app {
    width: 100%; 
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 800ms;
  }
  
  .image-headline, .image-app {
    opacity: 0.0; 
  }
  
  .image-app {
    left: 100%; 
    transition: left 1500ms, opacity 1500ms;
  }