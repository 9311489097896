.thumbnail-image {
  cursor: pointer;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 5px;
  object-fit: cover;
  margin: 0.5rem;
}
@media (max-width: 660px) {
  .thumbnail-image {
    width: 7rem;
    height: 7rem;
  }
}

@media (max-width: 550px) {
  .thumbnail-image {
    width: 7rem;
    height: 7rem;
  }
}

@media (max-width: 470px) {
  .thumbnail-image {
    width: 7rem;
    height: 7rem;
  }
}

@media (max-width: 400px) {
  .thumbnail-image {
    width: 6rem;
    height: 6rem;
  }
}


  .modal-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin: 20%;
    margin-top: 0.5rem;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    width: auto;
    max-width: 1280px;
    max-height: 90%;
    padding: 0.5rem;
    border: 1px solid #aaaaaa55;
    background-color: #22222255;
    backdrop-filter: blur(5px);
    animation: fade-in 0.5s ease-in;
  }

  @media (max-width: 1400px) {
    .modal-content {
      margin: 3%;
      margin-top: 0.5rem;
    }
  }


  @media (max-width: 1900px) {
    .modal-content {
      
      margin-top: 0.5rem;
    }
  }
    
  
  .modal-image-container {
    position: relative;
    max-width: 900px;
    margin: 0.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    background-color: none;
    
  }

  .modal-resubmit {
    align-items: center;
    justify-content: center;
    position:fixed;
    left: 45%;
    top: 5%;
    max-width: 20rem;
    margin: 1.5rem;
    box-shadow: 0px 4px 8px rgba(211, 211, 211, 0.75);
    background-color: rgb(255, 255, 255);

    border-radius: 12px;
  }
  
  .modal-image {

    max-width: 100%;

    

  }
  
  .modal-button {
    position: absolute;
    top: 45%;
    font-size: 5rem;
    color: #ffffffaa;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }

  .modal-image-actions {
    position: absolute;
    right: 5%;
    top: 5%;

  }

  .action-button {
    width: 4rem;
    height: 4rem;
    font-size: 2.5rem;
    margin: 0.75rem;
    color: #e6e6e6aa;
    background:  rgba(0, 0, 0, 0.25);
    border: none;
    cursor: pointer;
    
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    opacity: 0.7;
  }

  .action-button:hover {
    
    opacity: 1;
  }

  @media (max-width: 600px) {
    .action-button {
      width: 3.5rem;
      height: 3.5rem;
      font-size: 2rem;
    }
  }

  @media (max-width: 500px) {
    .action-button {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.5rem;
      margin: 0.5rem;
    }
  }
  
  .modal-button-prev {
    left: 10px;
  }
  
  .modal-button-next {
    right: 10px;
  }
  
  .thumbnail-container {
    display: box;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0.5rem;
    padding: 0.25rem;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    background-color: rgba(240, 240, 240, 0.95);
  }
  
  .thumbnail {
    cursor: pointer;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin: 0.5rem;
  }
  
  .active-thumbnail {
    border: 2px solid #007bff;
  }
  

  .page-button {
    
    cursor: 'pointer';
    text-align: center;
    border: none;
    border-radius: 4px;
    width: 1.75rem;
    height: 1.5rem;
    margin: 0.33rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    opacity: 0.70;
  }

  
  .page-button:hover {
    
    opacity: 1;
  }