.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
  }

  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .modal.fade-in {
    animation: fadeIn 330ms forwards;
  }
  
  .modal.fade-out {
    animation: fadeOut 330ms forwards;
  }

  .invitations-table {
    width: 100%;
    border-collapse: collapse;
    
  }
  
  .invitations-table th,
  .invitations-table td {
    border: 1px solid #8d8d8d;
    padding: 8px;
    text-align: left;
    
  }
  
  .invitations-table th {
    background-color: #f2f2f2;
    
  }
  
  .invitations-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
    
  }
  
  .invitations-table tbody tr:hover {
    background-color: #ddd;
  }
  
  .email-column {
    width: 100%;
  }
  
  