.products-container {
    padding: 5px;
    display: flex;
    width: 300px;
  }
  
  .plan-card {
    border: 1px solid #aaaaaa55;
    border-radius: 12px;
    padding: 20px;
    padding-top: 25px;
    margin: 1rem;
    box-sizing: border-box;

    display: flex;          /* Nastaví kartičku ako flex kontajner */
    flex-direction: column; /* Usporiada obsah vertikálne */
    align-items: center;    /* Zarovná obsah na stred horizontálne */
    justify-content: center;/* Zarovná obsah na stred vertikálne */
    text-align: center;     /* Zarovná text na stred */
   
    width: 280px;
    background-color: #00000015;
    backdrop-filter: blur(5px);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    animation: fade-in 0.5s ease-in;
  }

  .animated {
    animation: fade-in 0.5s ease-in;
  }

  
  .plan-card h2 {
    margin-top: 0;
    margin-bottom: 1px;
    font-size: 32px;
    color: #333333;
  }
  
  .plan-card p {
    margin: 0 0 10px 0;
    color: #505050;
    font-size: 1rem;
  }
  
  .plan-card ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .plan-card ul li {
    margin-bottom: 5px;
  }
  
  .plan-card button {
    padding: 10px 20px;
    border: none;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    font-size: 12pt;
    border-radius: 6px;
  }
  
  .plan-card button:disabled {
    background-color: #cccccc;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }



/* Štýl pre obrázky produktov */
.plan-card img {
  width: 100%; /* Nastaví šírku obrázka na 100% šírky kontajnera (karty) */
  height: auto; /* Zachová pomer strán obrázka */
}

/* Štýl pre text ceny */
.plan-card .price-text {
  font-size: 18pt; /* Veľkosť písma 16pt */
  font-weight: bold; /* Tučné písmo */
  color: #1f1f1f;
}

.bold-text {
  font-weight: bold;
  color: #353535 !important;
}

.period {
  
  font-size: 18px;  /* alebo akákoľvek iná hodnota, ktorú preferujete */
  padding-left: 0.5rem;
  font-weight: 400;
}