/* pridajte link na font awesome ikony */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");


@font-face {
  font-family: 'Akrobat';
  src: url('https://fonts.cdnfonts.com/s/14486/Akrobat-Regular.woff') format('woff');
  font-display: swap;
}


body {
  margin: 0;
  background-image: url('../images/background-v4.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: 'Akrobat', sans-serif;
  overflow-x: hidden; /* Zakáže horizontálne skrolovanie */
  overflow-y: auto; /* Umožní vertikálne skrolovanie */

  margin: 0;
  padding: 0;
  padding-bottom: 3rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

hr {

  border-color: #575757;
  border-width: 1px;
  border-top: #333;
  margin-top: 0.5rem;

}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
  max-width: 1000px;
}


h1 {
  font-size: 26pt;
}

p {
  font-size: 1.1rem;
}

  .login-page {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2rem;
    }

  .page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px;
    flex-wrap: wrap;
  }

  .app-logo {
    max-width: 25rem;
    width: 100%;
  }

  .logo {
    max-width: 25rem;
  }



  .app-card {
    border: 1px solid #49494955;
    border-radius: 12px;
    padding: 20px;
    padding-bottom: 40px;

    box-sizing: border-box;
    flex: 1 1 250px;
    max-width: 450px;
    margin: 10px;
    background-color: #0f141820;
    backdrop-filter: blur(5px);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    animation: fade-in 0.5s ease-in;
  }
  

  
  .login-card {
    border: 1px solid #aaaaaa55;
    max-width: 400px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    padding: 2.5rem;
    background-color: #00000015;
    backdrop-filter: blur(5px);
    border-radius: 12px;
  }

  .card {
    border: 1px solid #aaaaaa55;
    max-width: 600px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    padding: 1.5rem;
    background-color: #00000015;
    backdrop-filter: blur(5px);
    border-radius: 12px;
    margin: 1.5rem;
  }

  @media (max-width: 800px) {
    card {
      max-width: 400px;
    }
  }
  

  .price-card {
    margin: 1rem;
    text-align: center;
    width: 10rem;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    background-color: #596b7555;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
  }

  .price-card:hover {
    background-color: #ffffffaa;
    transition: background-color 0.2s ease-in-out;
  }

  .price-card.active {
    background-color: rgb(255, 255, 255);
    transition: background-color 0.2s ease-in-out;
  }
  .price-list {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Akrobat', sans-serif;
    margin: 3rem;
    padding: 1rem;
    border-radius: 12px;
  }

  .price-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    font-family: 'Akrobat', sans-serif;
  }
  
  .price-confirm p {
    text-align: center;
    margin-bottom: 1rem;
  }

  .input-field {
    background-color: #ffffffa2;
    border: 1px;
    border-style: solid;
    border-color: #969696;
    height: 1.5rem;
    border-radius: 5px;
    margin: 0.5rem;
    width: 10rem;
  }

  .input-button {
    background-color: #ffffffa2;
    border: 1px;
    border-style: solid;
    border-color: #969696;
    height: 2.0rem;
    padding-inline: 1rem;
    border-radius: 5px;
    margin: 0.5rem;
    opacity: 0.9;
    font-family: 'Akrobat', sans-serif !important;
  }

  .input-button:hover {
    background-color: #ffffffda;
    border-color: #4d4d4d;
    opacity: 1.0;

  }

  .submit-button {
    padding: 0.5rem 1rem;
    background-color: #3a4a55;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14pt;
    opacity: 0.8;
    font-family: 'Akrobat', sans-serif !important;
  }
  
  .submit-button:hover {
    opacity: 1;
  }



  
  .login-title {
    font-size: 28px;
    font-weight: 600;
    color: #21333f;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    text-align: center;
  }
  
  .login-form-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0.1rem;
    color: #333;
    display: block;
    }
    
    .login-form-input {
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 30px;
    min-width: 250px;
    font-size: 16px;
    margin: 0.5rem;
    transition: border-color 0.2s ease-in-out;
    width: 80%;
    padding-inline: 1rem;
    background-color: #eeeeee;
    }
    
    .login-form-input:focus {
    outline: none;
    border-color: #3a4b56;
    }
    
    .login-form-btn {
    background-color: #3a4b56;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    opacity: 0.85;
    font-family: 'Akrobat', sans-serif !important;

    min-width: 110px;
    width: auto;
    min-height: 2.5rem;
    height: auto;
    margin: 0.75rem;
    padding: 0.75rem;
    border-style: none;
    border-radius: 12px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    }
    
    .login-form-btn:hover,
    .login-form-btn:focus {
    background-color: #3a4b56;
    color: #fff;
    outline: none;
    box-shadow: none;
    opacity: 1;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    }
    
    .login-separator {
    text-align: center;
    margin: 1.5rem 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    }
    
    .login-google-btn {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    height: 40px;
    margin-top: 1.5rem;
    transition: background-color 0.2s ease-in-out;
    width: 90%;
    }
    
    .login-google-btn:hover,
    .login-google-btn:focus {
    background-color: #dadada;
    color: #555555;
    outline: none;
    box-shadow: none;
    }
    
    .login-register {
    margin-top: 1.5rem;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #333;
    }
    
    .login-register-link {
    color: #007bff;
    font-weight: 600;
    }
  
    /* definujte triedu pre ikonu Google */
.google-icon {
    color: #555555;
    font-size: 24px;
    margin-right: 1rem;
  }
  
  /* štýly pre tlačidlo "Prihlásiť sa cez Google" */
  .login-google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: #dfdfdf;
    color: #555555;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-google-btn:hover {
    background-color: #d4d4d4;
    transition: background-color 0.3s ease;
  }

  .gradient-background {
    background: linear-gradient(to left, #a54bb7, #5921a1);
    background-size: 200% 200%;
    /*animation: gradient 10s ease infinite;*/
  }

  .gradient-background-lite {
    background: linear-gradient(to left, #c7c7c7a6, #dbdbdb9a);
    background-size: 200% 200%;
    /*animation: gradient 15s ease infinite;*/
  }
  
/*
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
*/
  .no-gradient {
    background-color: #cccccc; /* alebo akákoľvek iná farba */
    animation: none;
  }

  .login-form-btn-disabled {
    background-color: #aaa;
    border-color: #aaa;
    cursor: not-allowed;
  }

  .login-form-btn-disabled:hover {
    background-color: #b3b3b3;
    border-color: #b3b3b3;
  }


  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
  
    50% {
      transform: translateY(20px);
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
    }
  }
  

  .loading-ball {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #333;
    animation: bounce 0.6s infinite;
  }
  
  @keyframes fade {
    0%, 100% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
  
    50% {
        opacity: 0.5;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
    }
}

.queue-container {
    display: flex;
    flex-wrap: wrap;
}

.queue-time {
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.queue-time .loading-text {
    animation: fade 3s infinite;
}

.footer-div {
  display: ;
  align-content: center;
}

.footer {
  background-color: #1c2733d7;
  color: #ffffff;
  font-size: 10pt !important;
  text-align: center ;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100% !important;
}

.billing-details-form {
  display: flex;
  flex-wrap: wrap;
}

.billing-details-column {
  flex: 1;
  padding: 10px;
}

.billing-details-actions {
  flex-basis: 100%;
  padding: 10px;
  text-align: center;
}

.billing-details-input{
  background-color: #ffffffa2;
  border: 1px;
  border-style: solid;
  border-color: #969696;
  height: 1.5rem;
  border-radius: 5px;
  padding-left: 0.5rem;
  margin-top: 0.15rem;
  margin-bottom: 0.5rem;
  width: 10rem;
}

@media (max-width: 525px) {
  .billing-details-input {
    width: 22rem;
  }
}

@media (max-width: 450px) {
  .billing-details-input {
    width: 18rem;
  }
}

@media (max-width: 390px) {
  .billing-details-input {
    width: 16rem;
  }
}

@media (max-width: 350px) {
  .billing-details-input {
    width: 14rem;
  }
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.spacer {
  flex-grow: 1;
}
