.table-container {
    margin: 20px;
    overflow-x: auto; /* For horizontal scrolling on small screens */
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    text-align: left;
    padding: 8px 12px;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
    cursor: pointer;
}

tbody tr:hover {
    background-color: #f5f5f5;
}
/* table.css */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltip-content {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: #f9f9f9;
    border: 1px solid #d4d4d4;
    width: 90px; /* Additional space for borders and possible padding */
    overflow: hidden; /* Hide images that are outside the box */
}

.tooltip:hover .tooltip-content {
    display: block;
}

@keyframes slide {
    0% { top: 0; }
    100% { top: -200px; }
}
