.bootstrap-grid .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    
  }
  
  .bootstrap-grid .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .bootstrap-grid .col,
  .bootstrap-grid [class^="col-"] {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .bootstrap-grid .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .bootstrap-grid .col-md-4 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  
  .bootstrap-grid .col-md-8 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  
  @media screen and (max-width: 1000px) {
    .bootstrap-grid .col-md-4,
    .bootstrap-grid .col-md-8 {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        width: 100%;
    }
  }


  
  
  /* Ak potrebujete ďalšie mriežkové triedy (napr. col-md-6, col-lg-3 atď.), 
     môžete ich pridať podľa potreby */
  