.container {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    font-family: 'Akrobat', sans-serif;
    
  }
  
  .progress-container {
    width: 100%;
    max-width: 500px;
  }
  
  .progress-container progress {
    width: 100%;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    font-family: 'Akrobat', sans-serif !important;

  }
  
  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .input-label {
    font-weight: bold;
  }
  
  .input-text {
    width: 60%;
    padding: 0.25rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #ffffffa8;
  }
  
  .input-file {
    cursor: pointer;
  }
  
  .submit-button {
    padding: 0.5rem 1rem;
    background-color: #3a4a55;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14pt;
    opacity: 0.8;
    font-family: 'Akrobat', sans-serif !important;
  }
  
  .submit-button:hover {
    opacity: 1;
  }

  .submit-button:disabled {
    opacity: 0.5;
    cursor: auto ;
  }
  
  .message {
    text-align: center;
    font-size: 0.9rem;
  }
  
  
.preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .preview-image {
    max-width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    }

    .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    }

    
.success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .success-message {
    font-size: 1.2rem;
    font-weight: bold;
    color: green;
    margin-bottom: 1rem;
  }
  
  .reset-button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition-duration: 0.4s;
  }
  
  .reset-button:d {
    background-color: #0069d9;
    color: white;
  }
  

  .preview-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .preview-wrapper {
    position: relative;
    width: calc(50% - 10px);
    max-width: 90%;
    margin: 5px;
  }

  .preview-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
  
  .remove-image-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    font-size: 14px;
    padding: 3px 6px;
    cursor: pointer;
    border-radius: 5px;
  }

  .remove-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    font-size: 14px;
    padding: 3px 6px;
    cursor: pointer;
  }