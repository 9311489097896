
.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background: transparent;
    outline: none;
    z-index: 2;
    pointer-events: all;
    position: relative;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
  }
  
  .slider::-moz-range-thumb {
    background: transparent;
    border: none;
  }
  
  .slider:after {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: white;
    transform: translateX(var(--slider-percentage, 50%));
    transition: transform 0.3s ease;
  }
  
 

  .comparisonWrapper {

    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    max-width: 90%;
    min-width: 270px;
  }

  .comparisonWrapper_l {

    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    max-width: 90%;
    min-width: 270px;
  }

  .comparisonWrapper_r {

    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    max-width: 90%;
    min-width: 270px;
    margin-left: 4rem;
    
  }

  .modal {
    width: 100%;   
    backdrop-filter: blur(5px)!important;
  }

  .modal-content {
    background-color: rgb(77, 77, 77);
    backdrop-filter: blur(5px)!important;
  }
  
  .welcomePageBlock {
    border: 1px solid #aaaaaa55;
    max-width: 1000px;
    height: auto;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    background-color: #00000020;
    backdrop-filter: blur(5px);
    border-radius: 12px;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    font-family: 'Akrobat', sans-serif;
    padding: 2rem;

  }

  video {
    max-width: 95%;
    min-width: 270px;
    padding-bottom: 2rem;   
    padding-top: 2rem;
   
  }
  
  .animated-title {
    color: #3a4b56;
    font-family: akrobat;
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  
  .animated-title span {
    display: inline-block;
  }
  
  .animate {
    opacity: 0;
    transform: translateY(0.32rem);
  }
  
  .animated-element {
    animation: jump 0.8s ease-in-out 0s forwards alternate;
  }

  .animate-slow {
    opacity: 0;
    transform: translateY(0.32rem);
  }
  
  .animated-element-slow {
    animation: jump-slow 1.5s ease-in-out 0s forwards alternate;
  }
  
  @keyframes jump {
    0% {
      transform: translateY(0.22rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes jump-slow {
    0% {
      transform: translateY(0.32rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animate-content {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .animate-content.animate-content {
    opacity: 1;
    transform: translateY(0);
  }

.in-text-link{

  color: rgb(56, 56, 56);
 }

  #logo-animation {

    display: block;
    margin: 0 auto;  
    padding: 0;
}

.round-button {

 min-width: 110px;
 width: auto;
 min-height: 2.5rem;
 height: auto;
 margin: 0.75rem;
 padding: 0.75rem;
 border-style: none;
 border-radius: 12px;
 box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
}

#description_r {
  text-align: right;
  max-width: 25%;
  padding-left: 15px;
  min-width: 200px;
  font-size: 1.1rem;
  }

#description_l {
  text-align: left;
  max-width: 25%;
  min-width: 200px;
  padding-left: 1.5rem;
  font-size: 1.1rem;
  }

#review {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  text-align: left;
  }  

#review_pros {
  font-size: 1rem;
  color: rgb(4, 61, 4);
  padding: 0;
  margin: 0;
  text-align: left;
  } 
  
#review_cons {
  font-size: 1rem;
  color: rgb(61, 4, 4);
  padding: 0;
  margin: 0;
  text-align: left;
  }

  #review_text {
    margin: 1rem;
  }

  #title{
    font-size: 3rem;
    font-weight: 700;
    color: #3a4b56;
    }


    @media screen and (max-width: 960px) {
      #description_r {
        text-align: justify;
        max-width: 90%;
        min-width: 290px;
        padding: 0;
        margin: 0;
        }

      #description_l {
          text-align: justify;
          max-width: 90%;
          min-width: 290px;
          padding: 0;
        margin: 0;
        }

      .comparisonWrapper_r {
        margin-left: 0;

       }

       #title{
        font-size: 2.0rem;
        }

        p{
          font-size: 1.0rem;
          }

    }


