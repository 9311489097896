@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .fade-out {
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    
  }

  .message-wrapper {
    position: relative;
    min-height: 2em;
    
  }
  
  