.navbar {
  font-family: 'Akrobat', sans-serif;
  font-size: 16pt;
  font-weight: 500;
  background-color: #1c2733d7;
  padding: 1rem;
  z-index: 100;
  justify-content: space-between;
  width: auto;
}

.navbar-left {
  display: flex;
  align-items: center;
}

@media (max-width: 520px) {
  .navbar-left {
    font-size: 14pt;
    margin: 0;
  }
}

.navbar-right {
  display: flex;
  align-items: center;
  font-size: 12pt;
}

@media (max-width: 1040px) {
  .navbar-left {
    flex: unset;
    margin-bottom: 1rem;
  }
}


.navbar a {
  text-decoration: none;
}

.nav-bar-logo {
  width: 160px;

}

.navbar-brand {
  font-size: 24px;
  color: #ecf0f1;
  transition: color 0.2s;
}

.navbar-brand:hover {
  color: #3498db;
}


.navbar-collapse {
  display: flex;
  justify-content: space-between;
}

.navbar-collapse .mr-auto {
  margin-right: 0;
}

.navbar-collapse .ml-auto {
  margin-left: 0;
}

.navbar-link,
.navbar-account-name,
.navbar-account-type,
.navbar-credit,
.navbar-icon,
.navbar-logout {
  color: #ecf0f1;
}

.navbar-link {
  font-weight: 500;
  padding: 0.5rem 0.35rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
}

.navbar-link-active,
.navbar-link:hover {
  background-color: #1a2530;
  color: #3498db;
}

.navbar-account-name,
.navbar-account-type,
.navbar-credit,
.navbar-icon {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.navbar-icon {
  font-size: 24px;
}

.navbar-logout {
  background-color: transparent;
  border: 2px solid #ecf0f1;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s;
}

.navbar-logout:hover {
  background-color: #ecf0f1;
  color: #2c3e50;
}

@media (max-width: 1040px) {
  .navbar-collapse {
    flex-direction: column;
  }

  .navbar-link {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 1040px) {
  .navbar-collapse {
    flex-direction: row;
  }

  .navbar-link {
    margin-right: 15px;
  }

  .navbar-account-name,
  .navbar-account-type,
  .navbar-credit,
  .navbar-logout {
    font-size: 16px;
    margin-right: 15px;
  }
}

.navbar-link-active {
  border-bottom: 2px solid #3498db;
}

.navbar-account-wrapper {
  display: flex;
  align-items: center;
}

.navbar-account-details {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.navbar-account-details span {
  white-space: nowrap;
}


.navbar-account-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 901px) {
  .navbar-account-details {
    flex-direction: row;
  }
}
